/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $( document ).ready( function( $ ) {
          
          // Global Nav Dropdowns

          $('.dropdown').hover(function(){
            $(this).toggleClass('show-dropdown');
          });

          // Hamburger Click

          $('.hamburger').click(function(){
            $('body').toggleClass('hide-overflow');
            $(this).toggleClass('hamburger-clicked');
            $('header').toggleClass('show-mobile-nav');
          });

          // For the presenters page

          $('.presenter-cards li').on('click', function(e){
            $('.content-contain').addClass('show-bio');
            $('.event-contain').css('display', 'none');
            $('.back-btn').css('display', 'none');
            $('.event-and-bio ul li:nth-child(2)').css('padding-left', '0px');
            $('.modal-contain').scrollTop(0);
            $('.modal-contain').addClass('show-modal');
            $('body').css('overflow', 'hidden');

            var el_two = $(e.target);
            el_two = el_two.children('span');

            // Presenter Detail

            var speaker_two_img = el_two.data('speaker-img');
            $('.presenter-headshot div').css('background-image', 'url(' + speaker_two_img + ')');

            var speaker_two_website = el_two.data('speaker-website');
            $('.website-link').attr('href', 'http://' + speaker_two_website);

            var speaker_two_email = el_two.data('speaker-email');
            $('.email-link').attr('href', 'mailto:' + speaker_two_email);

            var event_two_speaker = el_two.data('event-speaker');
            $('.bio-link').html(event_two_speaker);
            $('.bio h1').html(event_two_speaker);

            var speaker_two_qualifications = el_two.data('speaker-qualifications');
            $('.bio .qualifications').html(speaker_two_qualifications);

            var speaker_two_country = el_two.data('speaker-country');
            $('.bio .country').html(speaker_two_country);

            var speaker_two_bio = el_two.data('speaker-bio');
            $('.bio-contain article').html(speaker_two_bio);
          });

          // For the Cards on Event Pages

          $('.cards .btn, .plenary-event .btn').click(function(e){
            $('.modal-contain').scrollTop(0);
            $('.modal-contain').addClass('show-modal');
            $('body').css('overflow', 'hidden');

            var el = $(e.target);
            // el = el.parent().siblings('span');
            el = el.parent().parent().siblings('span');

            // Card Details

            var event_category = el.data('event-category');
            $('.card-cat').html(event_category);
            $('.modal-content figure').attr('class', event_category);

            var event_img = el.data('event-img');
            $('.background').css('background-image', 'url(' + event_img + ')');

            var event_title = el.data('event-title');
            $('.event-detail h1').html(event_title);

            var event_date = el.data('event-date');
            $('.event-detail .event-date').html(event_date);

            var event_venue = el.data('event-venue');
            $('.event-detail .event-venue').html(event_venue);

            var event_summary = el.data('event-summary');
            $('.event-detail article').html(event_summary);

            // Presenter Detail

            var speaker_img = el.data('speaker-img');
            $('.presenter-headshot div').css('background-image', 'url(' + speaker_img + ')');

            var speaker_website = el.data('speaker-website');
            $('.website-link').attr('href', 'http://' + speaker_website);

            var speaker_email = el.data('speaker-email');
            $('.email-link').attr('href', 'mailto:' + speaker_email);

            var event_speaker = el.data('event-speaker');
            $('.bio-link').html(event_speaker);
            $('.bio h1').html(event_speaker);

            var speaker_qualifications = el.data('speaker-qualifications');
            $('.bio .qualifications').html(speaker_qualifications);

            var speaker_country = el.data('speaker-country');
            $('.bio .country').html(speaker_country);

            var speaker_bio = el.data('speaker-bio');
            $('.bio-contain article').html(speaker_bio);
          });

          $('.modal-contain .close-icon, .overlay, .btn-close').click(function(){
            $('.modal-contain').removeClass('show-modal');
            $('.content-contain').removeClass('show-bio');
            $('body').css('overflow', 'visible');
            $('.event-contain').css('display', 'block');
          });

          // Bio Clicks

          $('.bio-link').click(function(){
            $('.content-contain').addClass('show-bio');

            setTimeout(function(){
              $('.event-contain').css('display', 'none');
            },100);
          });

          $('.back-btn').click(function(){
            $('.content-contain').removeClass('show-bio');
            $('.event-contain').css('display', 'block');
          });
        });

        // external js: isotope.pkgd.js

          // init Isotope
          var $grid = $('.grid').isotope({
            itemSelector: '.element-item'
          });

          var $filterButtons = $('.filters li');

          // store filter for each group
          var filters = {};

          // flatten object by concatting values
          function concatValues( obj ) {
            var value = '';
            for ( var prop in obj ) {
              value += obj[ prop ];
            }
            return value;
          }

          $('.filters').on( 'click', 'li', function() {
            var $this = $(this);
            // get group key
            var $buttonGroup = $this.parents('.button-group');
            var filterGroup = $buttonGroup.attr('data-filter-group');
            // set filter for group
            filters[ filterGroup ] = $this.attr('data-filter');
            // combine filters
            var filterValue = concatValues( filters );
            // set filter for Isotope
            $grid.isotope({ filter: filterValue });
          });

          // change is-checked class on buttons
          $('.button-group').each( function( i, buttonGroup ) {
            var $buttonGroup = $( buttonGroup );
            $buttonGroup.on( 'click', 'li', function() {
              $buttonGroup.find('.selected').removeClass('selected');
              $( this ).addClass('selected');
            });
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
